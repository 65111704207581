
.text{
    padding:0 0rem ;
    margin-bottom:1.5rem
}
.section-slider{
    padding:0rem 2rem 2rem 2rem;
    max-width: 1800px;
    margin: 0 auto;
}
.slideShow {
    position: relative;
    
}



.slideShow .slick-prev  {
    position: absolute;
    top: 48%;
    left: -20px;
    z-index: 1111111111111
}
.slideShow .slick-next{
    position: absolute;
    top: 48%;
    right:-20px;
    z-index: 1111111111111
}


.potrait-hover-details{
    display:grid;
    grid-gap:20px;
    z-index: 1 !important;
    padding:2rem;
    grid-template-areas:
    'header-left header-right'
    'middle middle'
    'footer-left footer-right';
    background-color: #009ba1;
    color:white !important;
    position: absolute;
    top: 100%
   
}
.potrait-hover-details>*,.potrait-hover-details>div>*{
   
    color:white ;
   
}
.item1{
    grid-area: header-left;
}
.item2{
    grid-area: header-right;
    justify-self: flex-end;
}
.item3{
    grid-area: middle
}
.item4{
    grid-area: footer-left;
}
.item5{
    grid-area: footer-right;
    justify-self: flex-end;
    align-self: flex-end;
}
.slider-container{
    position: relative;
    
}

.sliderBG{
    all:unset
}
.sliderBG>div{
    display: none;
    grid-gap:10px;
   flex-direction: column;
   height: 100%;
   width: 100%;
   font-style: italic;
   padding: 14px;
}
.sliderBG>div>*,.sliderbg2>div>*{
    margin-bottom: 0;
    color:white;
    font-size: 14px;
    font-weight:300
    
}
.slider-container:hover .sliderBG>div{
   display: flex;
   
}
.slider-container:hover .sliderBG{
   
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width:250px;
    max-height:250px;
    margin: 0 auto;
 
   
    background-color: #0000008c;
   
}
#slider2{
    position: relative;
}
.sliderbg2{
    all:unset
}
.sliderbg2>div{
    display: none;
    flex-direction: column;
    grid-gap: 5px;
    padding:14px;
}
#slider2:hover .sliderbg2>div{
    
    display: flex;
    justify-content: flex-end;
    height: 100%;
    
    
 }
#slider2:hover .sliderbg2{

   
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
   
    background-color: #0000008c;
   
}


.sliderBG3>div{
    display: none;
    grid-gap:10px;
   height: 100%;
   width: 100%;
   font-style: italic;
}
.sliderBG3>div>*{
    margin-bottom: 0;
    color:white;
    font-size: 14px;
    font-weight:300
    
}
.slider-container:hover .sliderBG3>div{
   display: flex;
   
}
.slider-container:hover .sliderBG3{
   
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 12%;
    max-width:250px;
    max-height:250px;
    margin: 0 auto;
   
    background-color: #0000008c;
   
}
@media screen and (max-width:600px) {
    .section-slider{
        padding:1rem 1.5rem 1rem 1.5rem
    }
    .text{
        padding:0 1rem ;
        margin-bottom:1.5rem
    }
    .slideShow .slick-prev {
        left:0
    }
    .slideShow .slick-next {
right: 0;
    }
    
}
@media screen and (max-width:450px) {
   
    .slideShow .slick-prev {
        left:-17px
    }
    .slideShow .slick-next {
right: -17px;
    }
    
}

p.sale{
    
    font-weight: 600;
    margin: 0px;
    color: white;
    background-color: red;
    text-align: center;
  
}
p.sale2{

font-weight: 600;
margin: 0px;
color: white;
background-color: red;
text-align: center;
width: 100%;
}
