
.free-consulting-conatiner1{
    display: flex;
    grid-gap:80px;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    
}

.free-consulting-conatiner1234{
    display: flex;
    grid-gap:80px;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
        background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner.jpg);

    background-size: 100% 50%;
    
}
.content-freeconsult>h2{
    padding: 0 0 8px;
    border-bottom: 2px solid #64c1c5;
    
    margin-bottom: 2rem;
    display: inline-block;
    font-weight: 300;

}
.content-freeconsult>h6{
    
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    
}


.content-freeconsult>p{
    
    word-spacing: 5px;
    color:grey;
    line-height: 24px;

    
}

.content-freeconsult123>h2{
    padding: 0 0 8px;
    border-bottom: 2px solid #64c1c5;
    
    margin-bottom: 1rem;
    display: inline-block;
    font-weight: 300;

}
.content-freeconsult123>h6{
    
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    
}


.content-freeconsult123>p{
    
    word-spacing: 5px;
    color:grey;
    line-height: 24px;

    
}

#free-consulting-img{
    width: 500px;
    height: 350px;
    object-fit: cover;



}
#free-consulting-img1{
width: 300px;
height: 270px;
object-fit: cover;
margin: 20px 20px 20px 20px;
}

#free-consulting-img2{
    width: 300px;
    height: 270px;
    object-fit: cover;
    margin: 20px 20px 20px 20px;
    float: right;
    }

.free-consult-form{
    display: grid;
    grid-column-gap: 30px;
}
.free-consult-form>div>input{
    height: 50px !important;
    border-radius: 4px;
}
.free-consult-form>div:nth-child(1){
    grid-column: 1 / 2;
}
.free-consult-form>div:nth-child(2){
    grid-column: 2 / 3;
}
.free-consult-form>div:nth-child(3){
    grid-column: 1 / 3;
}
.free-consult-form>div:nth-child(4){
    grid-column: 1 / 3;
}
.free-consult-form>button{
    grid-column: 1 / 3;
}
@media screen and (max-width:1200px) {
    .free-consulting-conatiner{
        flex-direction: column !important;
        grid-gap:40px
        
    }
    #free-consulting-img{
        width: 100%;
        height: 350px;
        object-fit: cover;
    
    
    
    }
    
}
@media screen and (max-width:450px) {
    .free-consulting-conatiner1{
       
        grid-gap:20px
        
    }
   
    
}

.abc{
        background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner.jpg);

    background-size: 100% 100%;
}
.abc1{
        background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner.jpg);

    background-size: 100% 100%;
}

img.tejas {
    width: 15vw;
    height: 15vw;
}
span#showdesc1234 {
position: relative;
left: 45vw;
font-size: 1vw;
}
span#showdesc12345 {
    position: relative;
    left: 45vw;
    font-size: 1vw;
    }

    @media (max-width: 480px) {
        span#showdesc1234 {
            position: relative;
            left: 35vw;
            font-size: 2.5vw;
            }
            span#showdesc12345 {
                position: relative;
                left: 35vw;
                font-size: 2.5vw;
                }
                .aboutus11.d-flex {
                    display: block!important;
                }
                img.ab11 {
                    width: 100%;
                }
                .team1 {
                    display: block!important;
                }
                img.team1img {
                    width: 100%!important;
                }
                .teamname {
                    margin-top: 4%;
                    border: 1px solid #5EBFC1;
                    width: 100%!important;
                    padding-left: 4%;
                    padding-top: 2%;
                    background-color: #5EBFC1;
                    color: #fff;
                }
                .team22 {
                    display: block!important;
                }
                .abc1212{
                    background-image: none!important;
                    background-size: 100% 50%;
                }
                img.ii.mx-3.my-5 {
                    width: 70%!important;
                }
                h1.achieve {
                    padding-top: 12%!important;
                    height: 75px!important;
                }
                img.iii.mx-3.my-5 {
                    width: 40%!important;
                }
                img.ai.mx-3.my-5 {
                    width: 40%!important;
                }
                p.driven {
                    border: 1px solid #5ebfc1;
                    color: #000;
                    margin: 0 0%!important;
                    padding: 10%!important;
                    text-align: justify;
                    width: 100%!important;
                }
    }


    .aboutus112 {
        padding: 3%;
    }


    h1.team11{
        text-align: center;
    border: 1px solid #E8EAEC;
    height: 80px;
    padding-top: 1rem;
    color: #5EBFC1;
    background-color: #E8EAEC;
    }

    .team1 {
        display: flex;
    }
    .teamname {
        margin-top: 4%;
    border: 1px solid #5EBFC1;
    width: 50%;
    padding-left: 4%;
    padding-top: 2%;
    background-color: #5EBFC1;
    color: #fff;
    }

    .team22 {
        display: flex;
        gap: 10%;
    }
    h2.free01 {
        height: 58px;
        color: #5EBFC1;
        border-bottom: 2px solid #5EBFC1;
        padding: 1%;
        margin-top: 5%;
    }

    .ach.container {
        text-align: center;
      
    }
    p.driven {
        border: 1px solid #5EBFC1;
        padding: 2%;
        text-align: justify;
        width: 70%;
        margin: 0% 15%;
        color: #000000;
    
    }

    h1.achieve{
        padding-top: 4%;
        height: 100px;
    }
    h4.achieve{
        margin-left: 0rem;
    }

    .major {
        border: 1px solid #F1F2F2;
        background: #F1F2F2;
        border-radius: 20px!important;
    }
    
    h1.achieves{
        padding-top: 4%;
        height: 100px;
        color:#009AA0!important;
    }

    img.iii.mx-3.my-5 {
        width: 20%;
      
    }
    @media (min-width: 600px) and (max-width:850px){

        .aboutus11.d-flex {
            display: block!important;
        }
        img.team1img {
            width: 100%;
            height: 100%;
        }
        .aboutus111 {
            text-align: center;
        }
        .teamname {
            margin-top: 4%;
            border: 1px solid #5EBFC1;
            width: 85%;
            padding-left: 4%;
            padding-top: 2%;
            background-color: #5EBFC1;
            color: #fff;
        }

        h2.free01 {
            height: 75px;
            color: #5EBFC1;
            border-bottom: 2px solid #5EBFC1;
            padding: 1%;
            margin-top: 5%;
        }
    }