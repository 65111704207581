.salebanner{

    grid-gap:50px;
    flex-wrap: wrap;
}

.salebanner-left,.salebanner-right{
    position: relative;
    cursor: pointer;
    
}
.salebanner-left>img,.salebanner-right>img{
    width: inherit;
    max-width: 500px;
}

.salebanner-left>div{
    background-color: transparent;
   border :2px solid white ;
   margin: 0 !important;
   padding :2rem 4rem ;
   position :absolute ;
   top: 50%;
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
   z-index: 1;
   display: flex;
   flex-direction: column;
   justify-content:center;
   align-items:center;

}
.salebanner-left>div>p{
   color:white;
   font-size: 24px;
    font-weight: 700;
    line-height: 126.19%;

}
.salebanner-left>div>h1{
   color:white;
   font-weight: 800;
font-size: 96px;
line-height: 126.19%;

}
.salebanner-left>div>h3{
   color:white;
   font-weight: 500;
font-size: 32px;
line-height: 126.19%;

letter-spacing: 0.15em;
margin-bottom: 1.5rem;

}

@media screen and (max-width:600px) {
    .salebanner-left>div{
     
       padding :1rem 2rem ;
      
    
    }
    .salebanner-left,.salebanner-right{
        position: relative;
        width: 100%
    }
    .salebanner-left>div>p{
        color:white;
        font-size: 20px;
         line-height:36px
     
     }
     .salebanner-left>div>h1{
       
     font-size: 50px;
     line-height:40px
     
     }
     .salebanner-left>div>h3{
        
     font-size: 22px;
     line-height: 36px
     
     
     }
    
}
@media screen and (max-width:350px) {
    .salebanner-left>div{
     
       padding :0.5rem 1rem ;
      
    
    }
    .salebanner-left,.salebanner-right{
        position: relative;
        width: 100%
    }
    .salebanner-left>div>p{
        color:white;
        font-size: 16px;
         line-height:22px
     
     }
     .salebanner-left>div>h1{
       
     font-size: 35px;
     line-height:26px
     
     }
     .salebanner-left>div>h3{
        
     font-size: 18px;
     line-height: 22px
     
     
     }
    
}