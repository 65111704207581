html { font-size: 15px !important; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  color: black;



}
div{
    border-radius: 0px !important;
}
img{
    border-radius: 0px !important;
}
select{
  border-radius: 0px !important;
}


.form-control{
  border-radius: 0px !important;
}
#root{
margin: 0;
padding: 0;

}
body {
  margin: 0 auto !important;
  padding: 0;
  
  
 min-width: 280px;
 
}

.link-a{
  all:unset
  
}
a:hover{
  cursor: pointer;
}
 
.section-margin{
  margin: 3rem 3rem !important ;
}
.section-padding{
  padding: 2rem 2rem !important ;
}
.section-paddingX{
  padding: 0 2rem !important ;
}
.section-marginY{
margin: 2rem 0 !important
}
.btn-design{
  padding: 0.6rem 1.5rem !important ;
  border: none !important;
  color:white !important;
  font-size: 16px;
line-height: 19px; 
background: #009ba1;

width: max-content;
height: fit-content;
}

.white-btn-design{
  padding: 0.8rem 1.5rem !important ;
  border: 2px solid #009ba1;
  color:#009ba1!important;
  font-size: 14px;
line-height: 19px; 
background: white;

}
.themeButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #009ba1;
  color: white;
  padding: 10px 25px;

  cursor: pointer;
  border: 1.5px solid #009ba1;

  transition: 0.2s ease-in-out;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: fit-content;
}
.white-themeButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:white;
  color: #009ba1;
  padding: 10px 25px;

  cursor: pointer;
  border: 1.5px solid #009ba1;

  transition: 0.2s ease-in-out;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: fit-content;
}

.themeButton:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #009ba1;
  border: 1.5px solid #009ba1;
  text-decoration: none;

}

.center-div{
  display: flex;
  align-items: center;
  justify-content:center;
}
.between-div{
  display: flex;
  align-items: center;
  justify-content:space-between;
}
.form-input{
  border:none !important;
  border-bottom: 2px solid #E3E3E3 !important;
backdrop-filter: blur(3px);
border-radius: 0 !important;
   
}
.input-name{
  font-weight: 700 !important;
  font-size: 14px !important;
  
}


.form-input:active{
  outline: none !important;
  border-radius: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
  }
input:focus-visible {
    outline:none;
  }
input:focus{
    outline:none;
    box-shadow: none !important;
  }
select option[hidden]{
    color:red !important
  }
 


.text-area{
  font-size: .875rem; 
  line-height: 1.25rem;
  font-weight: 600;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid  #e5e7eb; 
   
  height: 10rem;
  width:100%;

}


.form-btn{
  background: #315CB3 !important;
  box-shadow: 0px 8px 24px -2px rgba(11, 47, 138, 0.6) !important;

  width: 100% !important;
  padding: 1rem !important;
  max-width: 400px;
  display: block !important;
  margin: 4rem auto;
}
.section-subheading{
  text-transform: uppercase !important;
  font-weight: 700 !important;

}
.span-underline{
  font-style: normal;
  
  font-size: 15px;
  line-height: 126.19%;

  cursor: pointer;
  font-weight: 300;
  text-decoration: none;
  background-color: #009ba1;
  color:white;

  padding: 3px 10px;
  margin: 0 0 0 auto;
  margin-right: 22px;

}
.section-heading{
  font-style: normal;

font-size: 24px;
line-height: 126.19%;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  text-align: center;
  margin-top: 4rem ;

}

 @media screen and (max-width:1200px) {
  .section-margin{
    margin: 2rem 2rem !important ;
  }
  .section-padding{
    padding: 1rem 2rem !important ;
  }
  .section-marginX{
  margin: 0 2rem !important
  }
  .section-paddingX{
  padding: 0 2rem !important
  }
}

@media screen and (max-width:600px) {
  select,
  textarea,
  input {
    font-size: 14px !important;
  }
  .section-margin{
    margin: 1rem 1rem !important ;
  }
  .section-padding{
    padding: 2rem 1rem !important ;
  }
 
  .section-marginX{
  margin: 0 1rem !important
  }
  .section-paddingX{
  padding: 0 1rem !important
  }
  .container{
    width: 100% !important;
    
  }
  .section-subheading{
    font-size: 16px !important;
    margin: 0 1rem 1rem 1rem !important;
  
  }
  
.section-heading {
 
  
  font-size: 18px !important;
  margin-top: 2rem ;
  
}
.lighptgrey-p{
  font-weight: 500;
  line-height: 20px;
  font-size: 12px;
  color: #B5ABAB;
}
.form-btn{
  
  padding: 0.8rem !important;
  max-width: 200px;
  margin: 2rem auto;
  font-size: 0.8rem !important;
}
.white-btn-design{
  padding: 0.5rem 1rem !important ;

  font-size: 12px;
line-height: 15px; 

}
.btn-design{
  padding: 0.6rem 1.2rem !important ;
  
  font-size: 14px;
line-height: 15px; 

}
} 
.span-underline{
 
  
  font-size: 13px;
  
 
  font-weight: 300;
  
  padding: 3px 7px;
 

}
@media screen and (max-width:350px) {
  
  .white-btn-design{
    padding: 0.5rem 0.5rem !important ;
  
    font-size: 12px;
  line-height: 15px; 
  
  }
  .themeButton {
    padding: 7px 15px;
   
    font-size: 12px;
  
  }
  .white-themeButton {
    
    padding: 7px 15px;
   
    font-size: 12px;
   
  }
}

h1 {
  padding: 150px 0px 0px 0px;
  height: 350px;
  padding-top: 10rem;
}






.bi{
  font-size: 1.5rem !important;
}




@media (max-width: 480px) {
.banner{
  display: none!important;
}
}