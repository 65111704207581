
.free-consulting-conatiner111{
    display: flex;
  
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    
}

.content-freeconsult11>h2{
    padding: 0 0 8px;
    border-bottom: 2px solid #64c1c5;
    
    margin-bottom: 2rem;
    display: inline-block;
    font-weight: 300;

}
.content-freeconsult11>h6{
    
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    
}
.content-freeconsult11>p{
    
    word-spacing: 5px;
    color:grey;
    line-height: 24px;

    
}

#free-consulting-img{
    width: 500px;
    height: 350px;
    object-fit: cover;



}
.free-consult-forms{
    display: block;
    grid-column-gap: 30px;
}
.free-consult-forms>div>input{
    height: 50px !important;
    border-radius: 4px;
}
.free-consult-forms>div:nth-child(1){
    grid-column: 1 / 2;
}
.free-consult-forms>div:nth-child(2){
    grid-column: 2 / 3;
}
.free-consult-forms>div:nth-child(3){
    grid-column: 1 / 3;
}
.free-consult-forms>div:nth-child(4){
    grid-column: 1 / 3;
}
.free-consult-forms>button{
    grid-column: 1 / 3;
}
@media screen and (max-width:1200px) {
    .free-consulting-conatiner111{
        flex-direction: column !important;
        grid-gap:40px
        
    }
    #free-consulting-img{
        width: 100%;
        height: 350px;
        object-fit: cover;
    
    
    
    }
    
}
@media screen and (max-width:450px) {
    .free-consulting-conatiner111{
       
        grid-gap:20px
        
    }
   
    
}


img.tejas {
    width: 15vw;
    height: 15vw;
}

@media (max-width: 480px) {
    .free-consult-forms {
        display: block!important;
     
    }
    h1.free1 {
        height: 100px!important;
        color: #5EBFC1;
        border-bottom: 2px solid #5EBFC1;
        padding-top: 16%!important;
    }
    .free11 {
        display: block!important;
        gap: 5%;
    }
    .free1112 {
        display: block!important;
        gap: 5%;
    }
    .free-consulting-conatiner111.container {
        margin-top: 6rem;
        display: block!important;
    }
}

h1.free1 {
    height: 210px;
    color: #5EBFC1;
    border-bottom: 2px solid #5EBFC1;
    
}
.free11 {
    display: flex;
    gap: 10%;
}

.free1112 {
    display: flex;
    gap: 1%;
}

.free-consulting-conatiner111.container {
    margin-top: 6rem;
    display: flex;
}
.content-freeconsult11 {
    width: 100%;
}

form.free-consult-forms {
    padding: 3rem;
    background: #F8F9FA;
}