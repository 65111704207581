


.review-box>p{
    margin: 2rem auto ;
    width: max-content; 
    font-weight: 600;
font-size: 18px;
line-height: 21px;
}
.review-box>div{
    padding: 1.5rem;;
    display:flex;
    flex-direction:column;
    grid-gap:10px;
    align-items: center;
    justify-content:center;
    text-align: center;
    max-width:400px;
    box-shadow: 0px 0.864996px 8.64996px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}
.review-box>div>h6{
    font-family: system-ui;
}
.review-box>div>p{
    color: #8D8D8D;
    font-weight: 400;
    font-size: 13px;
    max-width: 300px;
}


.review-star{
    display: flex;
    grid-gap:10px
}
.testimonial-container{
    border : 1px solid rgba(0, 0, 0, 0.25);
 border-radius: 8px;
 padding: 2rem ;
 height: 90% !important;
 width: 90% !important;
 max-width: 300px;
 }
 
 .our-customer-right-text{
     font-weight: 400;
 font-size: 14px;
 line-height: 22px;
 }

@media screen and (max-width:600px) {
    .review-box>div{
    padding: 1rem;;
    
}
    
}