
.free-consulting-conatiner1{
    display: flex;
    grid-gap:80px;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    
}

.free-consulting-conatiner1234{
    display: flex;
    grid-gap:80px;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner.jpg);

    background-size: 100% 50%;
    
}
.content-freeconsult>h2{
    padding: 0 0 8px;
    border-bottom: 2px solid #64c1c5;
    
    margin-bottom: 2rem;
    display: inline-block;
    font-weight: 300;

}
.content-freeconsult>h6{
    
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    
}


.content-freeconsult>p{
    
    word-spacing: 5px;
    color:grey;
    line-height: 24px;

    
}

.content-freeconsult123>h2{
    padding: 0 0 8px;
    border-bottom: 2px solid #64c1c5;
    
    margin-bottom: 1rem;
    display: inline-block;
    font-weight: 300;

}
.content-freeconsult123>h6{
    
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    
}


.content-freeconsult123>p{
    
    word-spacing: 5px;
    color:grey;
    line-height: 24px;

    
}

#free-consulting-img{
    width: 500px;
    height: 350px;
    object-fit: cover;



}
#free-consulting-img1{
width: 300px;
height: 270px;
object-fit: cover;
margin: 20px 20px 20px 20px;
}

#free-consulting-img2{
    width: 300px;
    height: 270px;
    object-fit: cover;
    margin: 20px 20px 20px 20px;
    float: right;
    }

.free-consult-form{
    display: grid;
    grid-column-gap: 30px;
}
.free-consult-form>div>input{
    height: 50px !important;
    border-radius: 4px;
}
.free-consult-form>div:nth-child(1){
    grid-column: 1 / 2;
}
.free-consult-form>div:nth-child(2){
    grid-column: 2 / 3;
}
.free-consult-form>div:nth-child(3){
    grid-column: 1 / 3;
}
.free-consult-form>div:nth-child(4){
    grid-column: 1 / 3;
}
.free-consult-form>button{
    grid-column: 1 / 3;
}
@media screen and (max-width:1200px) {
    .free-consulting-conatiner{
        flex-direction: column !important;
        grid-gap:40px
        
    }
    #free-consulting-img{
        width: 100%;
        height: 350px;
        object-fit: cover;
    
    
    
    }
    
}
@media screen and (max-width:450px) {
    .free-consulting-conatiner1{
       
        grid-gap:20px
        
    }
   
    
}

.abc{
        background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner.jpg);

    background-size: 100% 100%;
}
.abc1{
        background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner.jpg);

    background-size: 100% 100%;
}
.abc12{
        background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner.jpg);

    background-size: 100% 50%;
}
img.tejas {
    width: 15vw;
    height: 15vw;
}
span#showdesc1234 {
    position: relative;
    left: 45vw;
    font-size: 1vw;
    }
    span#showdesc12345 {
        position: relative;
        left: 45vw;
        font-size: 1vw;
        }