.about-us-top{
    padding-top: 10rem;
    padding-bottom: 12rem;
    position :relative;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    z-index: 0;
}
.about-us-top::after{
    height: 100%;
    width: 100%;

    background-color: black;
    position :absolute;
   top:0;
   left:0;
    z-index: 1;
}
.about-us-top h1{
    text-align:center;
    font-size: 3rem;
    font-weight:700;
    color: #009ba1!important;
   

}

.about-us-bottom{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: -4rem;
    
    
}

.about-us-bottom-container{
    max-width: 1000px;
     width: 100%;
    display:flex;  flex-direction:column;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    padding: 2rem;

    z-index: 1;
    
}
.about-us-bottom-container h1{
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    
}
.about-us-bottom-container p{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1.5rem;
    
    
}
.about-us-bottom-container h3{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1.5rem
    
}
.contactus-btn button{
    background-color: orange;
    border-radius: 4rem;
    font-size: 1.5rem;
    padding: 1.4rem;
    margin: 2rem 2rem 1rem 0;
    color: white;
}
.bottom{
    position: relative;
    
    margin-top:-3rem;
    padding: 4rem;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.bottom h1{
    text-align:center;
    font-size: 4rem;
    font-weight:800;
    color: black!important;
    margin-top: 2rem;
   

}
.people{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
   justify-content: space-evenly;
    align-items: center;
    
}
.people div{
   margin: 2rem;
   padding:2rem;
    
    background-color: white;
    border-radius: 0.7rem;
    
    
}
.bottom-img img{
   max-width: 300px;  
   height:auto
   
}
.bottom-img h3{
    font-size: 3rem;
    font-weight: 500;
   
}
.bottom-img p{
    font-size: 2rem;
    font-weight: 300;
}


