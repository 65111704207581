
.main-div{
    background-color: #eaeaea;
   width: 100% !important;
   background-size: contain;
   background-position: center;
   height: 100%;
} 

.artgallery-logo{
   max-width: 180px;
   cursor: pointer;
}

 .menu>ul{
    padding: 0;;
    margin: 0;
list-style: none;
color: #000;
display: flex;
flex-direction: row;
align-items: center;

}
.nav-item{
    list-style-type: none !important;
    align-items: center;
    display: flex;
    
}
.nav-btn{
padding: 0 0.6rem !important;
font-weight: 600 !important;
color:black !important;
font-size: 12px !important;

}
.btn{
   background-color: #56BDBD;
   color: #fff;
}
.navbar>.container-fluid{
    grid-gap:40px ;
}
.Buttonofnavbar{
    position: relative;
    align-items: center;
}
.headerLink {
    padding: 0px 10px;
}

.headerLink>.nav-link {
    font-weight: 700;
    font-family: 'Raleway';
}

.headerLink>.nav-link:hover {
    color:#009ba1 !important;
}
.darkLink{
    text-decoration: none; 
    font-weight: 500;
    color:#626262;
    font-size: 13px;
    padding-left: 16px;
    cursor: pointer;
    
}
.darkLink:hover{
  
    color:#009ba1;
    
}
 .dropdown-item {
   padding: 0 !important;
   margin: 0 !important;

}
 .dropdown-item:hover {
   all:unset !important

}
.searchdropdown{
    position: absolute;
   top:100%;
    width: inherit;
    height: 250px;
    background-color: white;
    z-index: 11111;
    overflow: scroll;
    cursor: pointer;
   
}
#search:focus{
   
    background-color: white !important;
   
   
}
.searchdropdown>div{
    display: flex;
    padding: 8px;
    margin: 1rem;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 10px;
}
.searchdropdown>div>p{
    margin: 0;
    font-size:13px;
    font-weight:500;
}
.searchdropdown>div>img{
   width: 50px;
}

#carticon{
    display:none
}
@media screen and (max-width:1000px) {
    .navbar .container-fluid{
        grid-gap:20px !important;
    }
    .Buttonofnavbar{
        align-items: flex-start !important;
        
    
    }
    #signinnavbar{
        top: 16px;
        position: relative;
    }
    #dropdownnavbar{
        top:20px
    }
    .searchbaron1000{
        margin: 1rem 0 !important;
    }
    .nav-item{
       padding-bottom: 0.5rem;
        
 
    }
    #cartHide{
        display: none
    }
    #carticon{
        display: inline-block
    }
}
@media screen and (max-width:600px) {
    .artgallery-logo{
        width:80%;
    }
    .nav-link>span{
        display:none;
    }
    .navbar-toggler{
        font-size:14px !important;
    }
    .main-div{
        padding: 8px !important;
    }
    .searchdropdown>div>img{
       display: none;
     }
    
}
@media screen and (max-width:400px) {
    .artgallery-logo{
        width:50%;
    }
    #dropdownnavbar{
        top: 12px;
    
    }
    #signinnavbar{
        top: 9px;
        
    }

    
    
}


