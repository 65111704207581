
.free-consulting-conatiner{
    display: flex;
    grid-gap:80px;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    
}


.content-freeconsult1>h6{
    
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    
}
.content-freeconsult1>p{
    
    word-spacing: 5px;
    color:grey;
    line-height: 24px;

    
}

#free-consulting-img{
    width: 500px;
    height: 350px;
    object-fit: cover;



}
.free-consult-form{
    display: grid;
    grid-column-gap: 30px;
}
.free-consult-form>div>input{
    height: 50px !important;
    width: 250px !important;
    border-radius: 4px;
}
.free-consult-form>div:nth-child(1){
    grid-column: 1 / 2;
}
.free-consult-form>div:nth-child(2){
    grid-column: 2 / 3;
}
.free-consult-form>div:nth-child(3){
    grid-column: 1 / 3;
}
.free-consult-form>div:nth-child(4){
    grid-column: 1 / 3;
}
.free-consult-form>button{
    grid-column: 1 / 3;
}
@media screen and (max-width:1200px) {
    .free-consulting-conatiner{
        flex-direction: column !important;
        grid-gap:40px
        
    }
    #free-consulting-img{
        width: 100%;
        height: 350px;
        object-fit: cover;
    
    
    
    }
    
}
@media screen and (max-width:450px) {
    .free-consulting-conatiner{
       
        grid-gap:20px
        
    }
   
    
}

.section-heading1 {
    font-style: normal;
    font-size: 24px;
    line-height: 126.19%;
    text-transform: uppercase !important;
    font-weight: 400 !important;
    text-align: center;
    margin-top: 4rem;
}

span.section-heading1 {
  
    color: rgb(0, 155, 161);
}
h2.section-heading1 {
    position: relative;
    bottom: 4rem;
    left: 18rem;
}
@media screen and (max-width: 600px){
.section-heading1 {
    font-size: 18px !important;
    margin-top: 2rem;
    position: relative;
    bottom: 4rem;
    left: 18rem;
}
}


@media (min-width: 600px) and (max-width:850px){
    .contact-info-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: 1 1;
        margin: 0rem 0rem 0 0rem!important;
        padding: 1rem 1rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border-radius: 10px;
        color: black;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        max-width: 500px;
    }
}