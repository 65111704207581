.filterForm {
    padding: 8px 0px;
    display: flex;
    justify-content:space-between;
    align-items:center;
    max-width: 150px !important;
    margin-left:10px ;
}
.filterForm div {
     width: 100%; 
     cursor: pointer;
    
    
}
.filterForm div i{
    font-size: 1rem !important;
    font-weight: 900 !important;
    color:#56BDBD;
   
    
}

.filterForm select {
 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    height: 44px;
    border-radius: 5.66816px;
 width:100px;
    border: 0.70852px solid #56BDBD;
    cursor: pointer;
}

.processedbtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #56BDBD;
    color: white;
    margin: 1rem auto;
    height: 58px;
    cursor: pointer;
    border: 1.5px solid #56BDBD;
    border-radius: 8px;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    ;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    max-width:445px !important;
   
}
.voucher{
    border: 1px solid #C7C5C5;
    padding :2rem 2rem 2rem 3% !important;
    border-radius: 10px;
}

.voucher-input>input{
    height: 55px;
    border: 1px solid #C6C0C0;


}
.voucher-input>input:focus-visible{
    outline: none !important;

}
.voucher-input>button{
    width: 150px;
    height: 55px;
position: relative;
    color:white;
    border:none;
    background: #56BDBD;
 
    left: -10px;
    font-size: 0.8rem;
    top: 0px

}
.coupon{
  
    padding:1.5rem 0 ;
    border-top: 1px solid #DADADA;
    position: relative;
    width: 90%;
    
    
}
.coupon>h6{
   width: fit-content;
    background-color: white;
    margin:  0 auto;
    position: relative;
    padding: 0 0.5rem;
    top: -35px;
    left: 0;
}
.coupon>div>div{
   padding:1.5rem;
   background: #F3FAFA;
border: 1px dashed #56BDBD;
border-radius: 8px;
}
.coupan-lable{
    display: flex;
    grid-gap:20px;
    align-items: center;
}
.coupan-lable>p{
    padding: 0.5rem;
    border: 1px dashed #56BDBD;
border-radius: 8px;
font-size:0.7rem;
margin: 0;

}
@media screen and (max-width:600px) {
    .voucher-input>button{
        width: 100px;
        height: 45px;
   
    
    }
    .voucher-input>input{
        height: 45px;
       
    
    }
    
}

