.about-us-top h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    color: #009ba1!important;
}

.about-us-top {
    padding-top: 0rem;
    padding-bottom: 0rem;
    position: relative;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    z-index: 0;
}

img.shipping {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.about-us-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -4rem;
}

.about-us-bottom-container {
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    padding: 2rem;
    z-index: 1;
    background: white !important;
}

.about-us-bottom-container p {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

h2 {
    margin-left: 1rem;
    font-size: 1rem;
}

h4 {
    margin-left: 1rem;
    font-size: 1rem;
}

ul {
    margin-left: 3rem;
    font-size: 1rem;
}

p.content1 {
    margin: 4% 10% 0% 10%;
    background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner1.jpg);
    background-size: 100% 100%;
    
}