
.all-product {
    max-width: 2000px;
    margin: 20px 20px auto;
}
.all-product span{
   font-size: 13px;
}

.allproduct-top{
    display:flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    align-items: center;
}
.allproduct-top>select{
    padding: 1rem;
    width: 400px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    font-size:14px !important
}

.allproduct-left{
    padding: 1rem 2rem 1rem 2rem   ;
    background: #F4F1F1;
    border-radius: 8px;
    min-width: 280px;

    
    
}
.allproduct-left>div>p{
  margin: 0.5rem;
  font-weight: 600;

    
}
.product-filter-input{
padding: 0.5rem 0;
}
.product-filter-input>label{
    font-weight: 400;
    font-size: 12px !important;
    line-height: 126.19%;

    
    
    color: #ACA1A1;
}
.product-filter-input>input{
    width: 18px; 
  height: 18px;
background: #8E8B8B;
position: relative;
top: 3px;
left: 3px;

}

.allproduct-right{
   display: flex;
   grid-gap:10px 10px;
   flex-wrap: wrap;
   justify-content: space-evenly;
   height: max-content;
   margin-bottom: 1rem;
   display: grid;
   grid-template-columns: repeat(4,1fr);
   grid-template-rows: auto;
   
   
}
.allproduct-right>div{
  
   background: #FFFFFF;
   max-width: 300px;
   height: 100%;
border: 1px solid #D3D1D1;
border-radius: 8px ;


}
.allproduct-right>div>a>img{
   width: 100%;
   border-radius: 8px 8px 0 0 ;
   height: 300px;
   object-fit: cover;

}

.allproduct-right121{
    display: flex;
    grid-gap:10px 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: max-content;
    margin-bottom: 1rem;
    margin-left: 8rem;
    margin-right: 8rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: auto;
    
    
 }
 .allproduct-right121>div{
   
    background: #FFFFFF;
    max-width: 300px;
    height: 100%;
 border: 1px solid #D3D1D1;
 border-radius: 8px ;
 
 
 }
 .allproduct-right121>div>a>img{
    width: 100%;
    border-radius: 8px 8px 0 0 ;
    height: 300px;
    object-fit: cover;
 
 }

.allproduct-right1{
    display: flex;
    grid-gap:50px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: fit-content;
    margin-bottom: 1rem;

    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: auto;
    
    
 }

.allproduct-right1>div{
  
    background: #FFFFFF;
    max-width: 40vw;
    height: 100%;
    border: 1px solid #D3D1D1;
    border-radius: 8px ;
    text-align: center;
 
 
 }

 .allproduct-right1>div>a>img{
    width: 100%;
    border-radius: 8px 8px 0 0 ;
    height: 300px;
    object-fit: cover;
 
 }

 .allproduct-right2{

    grid-gap:50px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: fit-content;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: auto;
    gap: 30px;
    height: 100%;
    
    
 }

.allproduct-right2>div{
  
    background: #FFFFFF;
    max-width: 20vw;
    height: 100%;
 border: 1px solid #D3D1D1;

 
 
 }
 .allproduct-right2>div>a>img{
    width: 100%;
    border-radius: 8px 8px 0 0 ;
    height: 250px;
    object-fit: cover;
 
 }
.product-box-desc{
    margin:1rem;
    overflow: auto;
    height: 50px;
    font-size: 12px;
}
.product-box-desc>*{
    display: none;
   
}
.product-box-desc>p{
    display: block !important
   
}
::-webkit-scrollbar {
    width: 4px;
    
    }
    
    
    ::-webkit-scrollbar-thumb {
    background:#b2b2b2c1; 
    border-radius:80px;
   
    }
 .rangeInput {

        background-color: #DEE2E6;
        border-radius: 8px;
        height: 4px;
        width: 100%;
        outline: none;
        -webkit-appearance: none;
    }
    
    input[type='range']::-webkit-slider-thumb {
        width: 6px;
        -webkit-appearance: none;
        height: 12px;
        background: black;
        border-radius: 2px;
    }
    #filter-toggle{
        display: none
    }
    #belowwidth_650{
        display: none
    }


    @media screen and (max-width:650px) {

        #filter-toggle{
            display: inline-block
        }
        #abovewidth_650{
            display: none;
        }
        #belowwidth_650{
            display: inline-block;
            position: absolute;
            
        }
        .allproduct-top {
            
            justify-content: space-between;
        }
        .allproduct-top>select{
           
            width: 200px;
           
        }

        
    }
    
   

button.btn.btn-secondary.dropdown-toggle {
    background-color: #009ba1;
    border-color: #009ba1;
    width: 200px;
}

button.viewmore {

    font-size: 12px;
    width: 80px;
    height: 30px;
    margin: 8px;
    margin-top: -6px;
    color: white;
    background-color: #009ba1;
    border-color: #009ba1;
}
button.viewmore1 {

    font-size: 12px;
    width: 80px;
    height: 30px;
    margin: 8px;
    margin-top: 4px;
    color: white;
    background-color: #009ba1;
    border-color: #009ba1;
}


button.viewmore1 {
    position: sticky;
    top: 40vw;
}
button.viewmore123 {
    position: sticky;
    top: 45vw;
    font-size: 12px;
    width: 80px;
    height: 30px;
    margin: 8px;
    margin-top: -6px;
    color: white;
    background-color: #009ba1;
    border-color: #009ba1;
}

@media (max-width: 480px) {
    .allproduct-right1 {
        display: flex;
        grid-gap: 50px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: auto;
    }
    .allproduct-right1>div {
        background: #FFFFFF;
        max-width: 85vw;
        height: 100%;
        border: 1px solid #D3D1D1;
        border-radius: 8px;
        text-align: center;
    }
    .free-consulting-conatiner1.container {
        display: grid;
    }
    .allproduct-right2 {

        grid-gap: 50px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: auto;
        gap: 30px;
        height: 100%;
    }
    .allproduct-right2>div{
  
        background: #FFFFFF;
        max-width: 82vw;
        height: 100%;
     border: 1px solid #D3D1D1;

     
     
     }
     .allproduct-right121 {
        display: flex;
        grid-gap: 10px 10px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: max-content;
        margin: 0rem 0rem 0rem 1rem!important;
  
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: auto;
    }
    .allproduct-right121>div{
   
        background: #FFFFFF;
        width: 82vw;
        height: 100%;
     border: 1px solid #D3D1D1;
     border-radius: 8px ;
     
     
     }
     p.artistname {
        left: 0px!important;
        margin-left: 7rem;
        margin-left: 3rem!important;
    }
    .allproduct-right {
        display: flex;
        grid-gap: 10px 10px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: max-content;
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: auto;
    }
}


@media (min-width: 600px) and (max-width:850px){
    .testing.d-flex {
        display: block!important;
    
    }
    .allproduct-left {
        padding: 1rem 2rem 1rem 2rem;
        background: #F4F1F1;
        border-radius: 8px;
        min-width: 280px;
        margin-bottom: 4vw;
    }
    .allproduct-right1{
        display: flex;
        grid-gap:50px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: fit-content;
        margin-bottom: 1rem;
    
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: auto;
        
        
     }
     .allproduct-right2 {
  
        grid-gap: 50px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: auto;
        gap: 30px;
        height: 100%;
    }
    .allproduct-right2>div {
        background: #FFFFFF;
        max-width: 100%;
        height: 100%;
        border: 1px solid #D3D1D1;
  
    }

    .allproduct-right121 {
        display: flex;
        grid-gap: 10px 10px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: max-content;
        margin-bottom: 1rem;
        margin-left: 8rem;
        margin-right: 8rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: auto;
    }
    .allproduct-right {
        display: flex;
        grid-gap: 10px 10px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        height: max-content;
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: auto;
    }
}