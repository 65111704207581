.about-us-top h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
    color: #009ba1;
}

.about-us-top {
    padding-top: 0rem;
    padding-bottom: 0rem;
    position: relative;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    z-index: 0;
    border-bottom: 1px solid #009ba1;
}
h1.h11{
    font-size: 5rem;

}
h1.h112{
    font-size: 5rem;
    color: #fff!important;
}
h1.h111{
    font-size: 5rem;
    color: #fff!important;

}
img.faq {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.about-us-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    
}

.about-us-bottom-container {
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    padding: 2rem;
    z-index: 1;

}

.about-us-bottom-container p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1.5rem;
    margin-left: 1rem;

}
.about-us-bottom-container11 p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1.5rem;
    margin-left: 3rem;

}

.container {
    width: 100%;
    padding: 0 140px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 960px){
    .container {
        max-width: 900px;
    }
}

.accordion{
    list-style: none;
    margin: 0;
    padding: 0;
}

.accordion__item {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 1px solid #ebebeb;
    position: relative;
}

.accordion__trigger {
    display: flex;
    justify-content: space-between;
}

.accordion__trigger1 {
    display: flex;
    margin-bottom: 16px;
}

.v-icon.v-icon {
    align-items: center;
    display: inline-flex;
    font-feature-settings: "liga";
    font-size: 24px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    text-indent: 0;
    transition: .3s cubic-bezier(.25,.8,.5,1),visibility 0s;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


h2 {
    margin-left: 1rem;
    font-size: 1rem;
}

h4 {
    margin-left: 3rem;
    font-size: 1rem;
}

ul {
    margin-left: 3rem;
    font-size: 1rem;
}

.accordion__content {
    display: none;
}

.accordion__content1 {
    text-align: left;
    width: 95%;
}



p.content {
    margin: 4% 10% 0% 10%;
    background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner1.jpg);
    background-size: 100% 25%;
    
}

p.content1 {
    margin: 4% 10% 0% 10%;
    background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner1.jpg);
    background-size: 100% 100%;
    
}
span#showdesc1 {
    position: relative;
    left: 50vw;
    font-size: 1vw;
}
img.tejas {
    width: 20vw;
    height: 20vw;
    margin: 30px 30px 30px 30px;
    border: 1px solid #8E8B8B;
}

.allproduct-right2231 {
    margin: 20px 20px 20px 20px;
}
@media (max-width: 480px) {
    h1.h11 {
        font-size: 3rem;
    }
    h1.h112 {
        font-size: 3rem;
        color: #fff!important;

    }
    h1.h111 {
        font-size: 2rem;
        color: #fff!important;
    }
}

@media (min-width: 600px) and (max-width:850px){

    h1.h111 {
        font-size: 4rem;
        color: #fff!important;
    }

    
}

