
.product-details{
    display:flex;
    grid-gap:40px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    margin: 2rem 0;

    
}
.product-details::before{
   content: " ";
   height: 100%;
   width: 100%;
  background-size: cover;
   position: absolute;
   top:0;left:0;
   z-index: -1;
   max-width:none;
    
}
.product-details1{
    display:flex;
    grid-gap:40px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    margin: 2rem 0;

    
}
.product-details1::before{
   content: " ";
   height: 100%;
   width: 100%;
  background-size: cover;
   position: absolute;
   top:0;left:0;
   z-index: -1;
   max-width:none;
    
}
.product-details-left{
  flex: 1;
  justify-content: flex-start;
    display: flex;
    max-width: 750px;
    align-items: flex-start;
    flex-direction: column;
    grid-gap:20px
   

}
.product-details-left8{
    flex: 1;
    justify-content: flex-start;
      display: flex;
      max-width: 750px;
      align-items: flex-start;
      flex-direction: column;
      grid-gap:0
     
  
  }

.product-details-left123{
    flex: 1;
    justify-content: flex-start;
      display: flex;
      max-width: 750px;
      align-items: flex-start;
      flex-direction: column;
      grid-gap:20px
     
  
  }

  .product-details-left123>img{
    width:100%;
    object-fit: contain;
    min-width: 250px;
    cursor: pointer;
    border: 1px solid #D3D1D1;
   
   }
.product-details-left>img{
 width:100%;
 object-fit: contain;
 min-width: 250px;
 cursor: pointer;

}
.product-details-left8>img{
    width:100%;
    object-fit: contain;
    min-width: 250px;
    cursor: pointer;
   
   }
.product-details-right{
    flex: 1;
    flex-direction: column;
    padding-top: 1rem ;
    max-width: 750px;
    
    
}
.product-details-left1 {
    cursor: pointer;
}
.product-details-right>h2{
    font-weight: 600;
font-size: 1.8rem;
line-height: 126.19%;
    
}
.product-details-right>h3{
    font-weight: 500;
font-size: 1.3rem;
line-height: 30px;
    
}
.product-details-right>p,.product-details-right>div>p{
    font-weight: 400;
    font-size: 13px;
    line-height:20px;
    color: #726E6E;
}
.product-details-right>div{
  margin: 1.2rem 0 ;
}
.product-btn{
    margin-top: 1.5rem;
    width: 402px;
    height: 61px;
    display: flex;
    justify-content:center;align-items: center;
    background-color: #56BDBD;
    border: 2px solid #56BDBD;
    color: white;
    border-radius: 6px;
    transition: 0.2s ease-in-out;
}
.white-product-btn{
    margin-top: 1.5rem;
    width: 402px;
    height: 61px;
    display: flex;
    justify-content:center;align-items: center;
    background-color: white;
    border: 2px solid #56BDBD;
    color: #56BDBD;;
    border-radius: 6px;
    transition: 0.2s ease-in-out;
    
}
.product-btn:hover{
  border: 2px solid #56BDBD;
    background-color: white;
    color: #56BDBD;
}
#description-text>h3{
    font-size:20px !important
}
#description-text>p{
    line-height:22px
}
@media screen and (max-width:1000px) {
    #description-text>h3{
        font-size:18px !important
    }
    #description-text>p{
       font-size: 16px;
    }
    .product-details-right>p,.product-details-right>div>p{
        margin-bottom: 0.5rem;
        
        
    }
}
@media screen and (max-width:800px) {
    .product-details{
        flex-direction: column;   
    }
}
@media screen and (max-width:600px) {
    .product-btn{    
        width: 200px;
        height: 50px;
    }
    .white-product-btn{ 
        width: 200px;
        height: 50px; 
    }
    #description-text>p{
        font-size: 13px;
     }
}

p.sale{
    
        font-weight: 600;
        margin: 0px;
        color: white;
        background-color: red;
        text-align: center;
      
}
p.sale1{
    font-weight: 600;
    margin: 0px;
    color: white;
    background-color: red;
    text-align: center;
    width: 100%;
    }

span#showdesc2 {
     
        font-size: 1vw;
        position: relative;
    left: 21vw;
    }

    p#description-text {
        word-spacing: 5px;
        color: grey;
        line-height: 24px;
        text-align: justify;
    }


    @media (max-width: 480px) {
        .product-details1{
            display:flex;
            grid-gap:40px;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            position: relative;
            margin: 2rem 0;
            background-image: url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/WebsiteBanner.jpg);

            background-size: 100% 25%;
            
        }
        p#description-text {
            margin-left: 48px;     
            margin-right: 20px;
        }
        .product-details-left8>img{
            width:96%;
            object-fit: contain;
            min-width: 250px;
            cursor: pointer;
           
           }
           .product-details-left8{
            width:96%;
           }
           .product-details-right{
            width:96%;

           }
           img.image.g-image {
            width: 100%;
        }
        span#showdesc2 {
     
            font-size: 2.5vw;
            position: relative;
        left: 30vw;
        }
    
}
@media (min-width: 600px) and (max-width:850px){



    
    }